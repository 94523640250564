import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

// Containers
import { Banner, ArticlesList } from "../containers/articles"

const ArticlesPage = ({ data, pageContext }) => {
  const {
    ogtitle,
    ogdescription,
    title,
    details,
    suggestButtonBottomLabel,
    articlesListCategories,
  } = data.article
  const { edges: articlesList } = data.articles

  const bannerData = { title, details, suggestButtonBottomLabel }
  const articlesData = { articlesList, categories: articlesListCategories }
  return (
    <Layout>
      <SEO title={ogtitle} description={ogdescription} />
      <Banner data={bannerData} />
      <ArticlesList data={articlesData} context={pageContext} />
    </Layout>
  )
}

export const query = graphql`
  query ArticlesPageQuery {
    article: contentfulArticlesPage {
      ogtitle
      ogdescription
      title {
        raw
      }
      details
      suggestButtonBottomLabel
      articlesListCategories
    }
    articles: allContentfulArticles(sort: { fields: createdAt, order: DESC }) {
      edges {
        node {
          title
          imageUrl
          articleLink
          category
          authorName
        }
      }
    }
  }
`

export default ArticlesPage
