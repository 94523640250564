import React, { useState } from "react"

// Components
import { ArticlesList } from "../../../containers/home/articlesSection"
import Pagination from "../../../components/pagination"
import AdSection from "../../../components/adSection"

// Styles
import styles from "./index.module.scss"

const ArticleTabs = ({ categories, selectedTab, toggle }) => {
  return (
    <div className={styles.articleTabs}>
      <ul
        className={`${styles.tabSelector} md:flex justify-between items-center`}
      >
        {categories.map((articleTab, index) => (
          <li
            key={index}
            className={`${styles.tab} ${
              selectedTab === index ? styles.activeTab : ""
            } `}
            onClick={() => toggle(index)}
          >
            {articleTab}
          </li>
        ))}
      </ul>
    </div>
  )
}

const ArticleListSeciton = ({ data: { categories, articlesList } }) => {
  const [tab, setTab] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)
  const toggleFunc = index => {
    setTab(index)
    setCurrentPage(1)
  }

  const sortedData = categories.map(cat => {
    return {
      cat: cat,
      list: articlesList.filter(article => article.node.category === cat),
    }
  })

  const paginatedData = sortedData[tab].list.slice(
    currentPage === 1 ? 0 : currentPage + 7,
    currentPage * 9
  )

  const pagination = {
    currentPage,
    pagesNum: Math.ceil(sortedData[tab].list.length / 9),
  }

  return (
    <div className={styles.tributesListSection}>
      <div className="max-w-6xl mx-auto px-4 xl:px-0">
        <ArticleTabs
          categories={categories}
          selectedTab={tab}
          toggle={toggleFunc}
        />
        <div className={styles.articlesListContainer}>
          <ArticlesList data={paginatedData} minimal />
        </div>
        {pagination.pagesNum > 1 && (
          <Pagination
            route="articles"
            data={pagination}
            setCurrentPage={setCurrentPage}
          />
        )}

        {/* <AdSection /> */}
      </div>
    </div>
  )
}

export default ArticleListSeciton
