import React from "react"
import { Link } from "gatsby"
import { BLOCKS, MARKS } from "@contentful/rich-text-types"

// Components
import RichTextRenderer from "../../../components/richTextRenderer"

// Styles
import styles from "./index.module.scss"

// Images
import stroke from "../../../images/stroke2.svg"

const options = {
  renderNode: {
    [BLOCKS.HEADING_1]: (node, children) => (
      <h1 className={styles.title}>{children}</h1>
    ),
  },
  renderMark: {
    [MARKS.BOLD]: text => {
      return <span className={styles.highlightedText}>{text}</span>
    },
  },
}

const Banner = ({ data: { title, details, suggestButtonBottomLabel } }) => {
  return (
    <div id="banner" className={styles.banner}>
      <div className="max-w-6xl mx-auto px-4 xl:px-0">
        <div className="md:flex justify-between items-center">
          <div className={`${styles.headingSection} w-full md:w-11/12`}>
            <RichTextRenderer content={title.raw} options={options} />

            <p className={`${styles.description} mb-6 md:mb-0 mt-6`}>
              {details}
            </p>
          </div>
          <div className={`${styles.ctaSection} flex flex-col items-end`}>
            <Link to="/suggest-article" className="btn twine-gradient">
              Suggest an Article
            </Link>
            <p>{suggestButtonBottomLabel}</p>
          </div>
        </div>
        <img src={stroke} alt="horizontal stroke" className={styles.stroke} />
      </div>
    </div>
  )
}

export default Banner
